import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from '@auth0/auth0-angular';
import { PageComponent } from '../common/page/page.component';

@Component({
  selector: 'logout',
  standalone: true,
  imports: [CommonModule, PageComponent],
  templateUrl: './logout.component.html',
})
export class LogoutPageComponent {
  constructor(private auth: AuthService) { }

  handleLogin(): void {
    this.auth.loginWithRedirect({
      appState: {
        target: '/home',
      },
    });
  }
}
