import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { AptemComponent } from '../common/directives/aptem-component/aptem-component';
import { ButtonConfig } from './button.models';

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent extends AptemComponent<ButtonConfig> {
  @Output() public clicked: EventEmitter<null> = new EventEmitter();

  protected defaultConfig: Required<ButtonConfig> = {
    color: 'primary',
    disabled: false,
    elementId: null,
    size: 'md',
    type: 'button',
  };

  protected handleClick(): void {
    const { type, disabled } = this.getConfig;

    if ((type === 'button' || type === 'submit') && !disabled) {
      this.clicked.emit();
    }
  }
  
}
