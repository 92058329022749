<button
  [attr.id]="getConfig.elementId || null"
  [attr.data-ua-id]="trackingId"
  [disabled]="getConfig.disabled"
  [type]="getConfig.type"
  [ngClass]="['button-' + getConfig.color, 'button-' + getConfig.size]"
  class="button"
  (click)="handleClick()"
>
  <ng-content />
</button>
