import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { PageComponent } from '../common/page/page.component';

@Component({
  selector: 'error',
  templateUrl: './error.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [PageComponent],
})
export class ErrorComponent {
  errorMessage: string;

  constructor(private router: Router) {
    // Access the error message from the router state
    const navigation = this.router.getCurrentNavigation();
    this.errorMessage =
      navigation?.extras.state?.['error'] || 'An error occurred';
  }
}
