<nav class="navbar bg-flats-white b-b-border-main">
  <svg-icon
    src="assets/images/aptem-assess-logo.svg"
    data-ua-id="navbar-logo"
  ></svg-icon>

  <ng-container>
    <div class="login-nav">
      @if ((logoutVisible$ | async) === true) {
        <img
          [src]="(user$ | async)?.picture"
          alt="Profile"
          class="profile-picture m-r-1"
        />

        <div class="action-button-block-sm-end-md">
          <app-button
            trackingId="assessment-logout"
            [config]="{color: 'secondary'}"
            (clicked)="handleLogout()"
          >
            Log out
          </app-button>
        </div>
      } @else {
        <div class="action-button-block-sm-end-md">
          <app-button
            trackingId="assessment-login"
            [config]="{color: 'secondary'}"
            (clicked)="handleLogin()"
          >
            Log in
          </app-button>
        </div>
      }
    </div>
  </ng-container>
</nav>
