import { Directive, Input } from '@angular/core';

@Directive()
export abstract class AptemComponent<T> {
  @Input({ required: false }) public config: T;
  @Input({ required: true }) public trackingId: string;

  protected abstract defaultConfig: Required<T>;

  protected get getConfig(): T {
    return {
      ...this.defaultConfig,
      ...this.config,
    };
  }
}
