<div class="page-layout">
  @if (content) {
    <div
      class="page-layout-content"
      [class.display-hidden]="config?.loading"
      data-ua-id="page-content"
    >
      <ng-container [ngTemplateOutlet]="content"></ng-container>
    </div>
  }
</div>
