<div class="campaign-container">
  <h2 class="m-b-3 text-medium">Campaigns</h2>

  <div class="result-container">
    @for (item of (campaignSummary$ | async)?.campaigns; track item.name) {
      <div class="row">
        <div class="segment first-segment">
          <h4>{{ item.name }}</h4>
          <div class="divider"></div>
        </div>
        <div class="content-segments">
          <div class="segment">
            <div class="segment-content second">
              <div class="segment-top">Invites sent</div>
              <div class="segment-bottom">{{ getFormattedNumber(item.invitesSent) }}</div>
            </div>
            <div class="divider"></div>
          </div>
          <div class="segment">
            <div class="segment-content third">
              <div class="segment-top">Responses</div>
              <div class="segment-bottom">{{ getFormattedNumber(item.assessmentsCompleted) }}</div>
            </div>
            <div class="divider"></div>
          </div>
          <div class="segment">
            <div class="segment-content fourth">
              <div class="segment-top">Last response</div>
              <div class="segment-bottom">
                <span *ngIf="getFormattedDaySpan(item.daysSinceLastResponse).count > 0"
                  >{{ getFormattedDaySpan(item.daysSinceLastResponse).count }}&nbsp;</span
                ><span class="suffix">{{ getFormattedDaySpan(item.daysSinceLastResponse).suffix }}</span>
              </div>
            </div>
            <div class="divider"></div>
          </div>
          <div class="segment">
            <div class="segment-content fifth">
              <div class="segment-top">Support indicated</div>
              <div class="segment-bottom">
                {{ getFormattedNumber(item.supportIndicated) }} <span class="suffix">respondents</span>
              </div>
            </div>
          </div>
        </div>
        <div class="segment last-segment">
          <div class="download-button">
            <a
              (click)="getCampaignResults(item.code)"
              class="download-link"
            >
              <svg-icon
                src="assets/images/icon-download.svg"
                data-ua-id="download-icon"
                class="download-icon"
              ></svg-icon>
              Download
            </a>
          </div>
        </div>
      </div>
    } @empty {
      <div class="grid-table">
        <div class="cell">There are no items.</div>
        <div class="cell"></div>
      </div>
    }
  </div>

  <div>
    <h2 class="m-t-5 m-b-3 text-medium">Guides</h2>
    <ul class="guide-block">
      @for (item of (campaignSummary$ | async)?.guideUrls; track item.fileName) {
        <li class="m-b-1">
          {{ item.fileName }}
          <a
            href="{{ constructUrl(item) }}"
            target="_blank"
            >(download {{ item.fileExtension | uppercase }})</a
          >
        </li>
      }
    </ul>
  </div>
</div>
