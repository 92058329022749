import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
} from '@angular/core';
import { PageConfig } from './page.models';

@Component({
  selector: 'app-page',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './page.component.html',
  styleUrl: './page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageComponent {
  @Input() public config: PageConfig | null = null;
  @Input({ required: true }) public content: TemplateRef<any> | null = null;
  @Input() public action: TemplateRef<any> | null = null;
}
