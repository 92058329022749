<app-page [content]="content" />

<ng-template #content>
  <h1 class="text-bold m-t-5 m-b-3">Something unexpected happened...</h1>

  <p>
    Unfortunately we're not able to understand your request.
  </p>

  <p>{{ errorMessage }}</p>
</ng-template>
