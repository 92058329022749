import { AsyncPipe, CommonModule, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { CampaignDashboardsResponse, GuideUrl } from '../models/response-models';
import { AdminPortalApiService } from '../services/admin-portal-api.service';
import { SvgIconComponent } from 'angular-svg-icon';

@Component({
  selector: 'campaign-results',
  templateUrl: './campaign-results.component.html',
  styleUrl: './campaign-results.component.scss',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe, SvgIconComponent, NgIf, CommonModule],
})
export class CampaignResultsComponent {
  protected campaignSummary$: Observable<CampaignDashboardsResponse>;

  constructor(private apiService: AdminPortalApiService) {
    this.campaignSummary$ = this.apiService.getDashboards();
  }

  public getCampaignResults(campaignCode: string) {
    this.apiService.getCampaignResults(campaignCode).subscribe((blob) => {
      const a = document.createElement('a');
      const objectUrl = URL.createObjectURL(blob);
      a.href = objectUrl;
      a.download = `${campaignCode}_${new Date().toISOString()}.csv`;
      a.click();
      URL.revokeObjectURL(objectUrl);
    });
  }

  protected getFormattedNumber(number: number) : string {
    const formatter = Intl.NumberFormat('en', { notation: 'compact' });
    return formatter.format(number);
  }

  protected getFormattedDaySpan(numberOfDays: number | null): {count: number, suffix: string} {
    if (numberOfDays === null) {
      return { count: 0, suffix: 'No responses' };
    }
    let suffix: string;
    let count: number;
    if (numberOfDays == 0) {
      count = numberOfDays;
      suffix = `Today`;
    }
    else if (numberOfDays < 7) {
      count = numberOfDays;
      suffix = `day${numberOfDays !== 1 ? 's' : ''} ago`;
    } else if (numberOfDays <= 28) {
      const weeks = Math.floor(numberOfDays / 7);
      count = weeks;
      suffix = `week${weeks !== 1 ? 's' : ''} ago`;
    } else {
      const months = Math.floor(numberOfDays / 28);
      count = months;
      suffix = `month${months !== 1 ? 's' : ''} ago`;
    }

    return { count, suffix };
  }

  protected constructUrl(guideUrl: GuideUrl) {
    return guideUrl.uri + '/' + guideUrl.fileName + '.' + guideUrl.fileExtension;
  }
}
