import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { AuthService, User } from '@auth0/auth0-angular';
import { SvgIconComponent } from 'angular-svg-icon';
import { map, Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { ButtonComponent } from "../../../aptem-component-library/components/button/button.component";

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [SvgIconComponent, AsyncPipe, ButtonComponent],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent {
  protected user$: Observable<User | null | undefined>;
  protected code$: Observable<string>;
  logoutVisible$: Observable<boolean>;

  constructor(@Inject(DOCUMENT) public document: Document,
    private auth: AuthService) {
    this.user$ = this.auth.user$;
    this.code$ = this.user$.pipe(map((user) => JSON.stringify(user, null, 2)));
    this.logoutVisible$ = auth.isAuthenticated$;
  }

  handleLogin(): void {
    this.auth.loginWithRedirect({
      appState: {
        target: '/home',
      },
    });
  }

  handleLogout(): void {
    this.auth.logout({
      logoutParams: {
        returnTo: `${this.document.location.origin}/logout`
      }
    });
  }
}
