import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PageComponent } from '../common/page/page.component';
import { AuthService, User } from '@auth0/auth0-angular';
import { Observable } from 'rxjs';
import { AsyncPipe, NgIf } from '@angular/common';
import { CampaignResultsComponent } from "../campaign-results/campaign-results.component";

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [PageComponent, AsyncPipe, CampaignResultsComponent, NgIf],
})
export class HomePageComponent {
  protected user$: Observable<User | null | undefined>;

  constructor(
    private readonly auth: AuthService
  ) {
    this.user$ = this.auth.user$;
  }
}
