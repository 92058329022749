import { Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { CampaignResultsComponent } from './campaign-results/campaign-results.component';
import { ErrorComponent } from './error/error.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { LogoutPageComponent } from './logout/logout.component';
import { HomePageComponent } from './home/home.component';

export const routes: Routes = [
  {
    path: 'home',
    component: HomePageComponent,
    canActivate: [AuthGuard],
    title: 'Aptem Assess Admin Centre | Dashboard',
  },
  {
    path: 'campaign-results',
    component: CampaignResultsComponent,
    canActivate: [AuthGuard],
    title: 'Aptem Assess Admin Centre | Campaign Results',
  },
  {
    path: 'login',
    component: LoginPageComponent,
    title: 'Aptem Assess Admin Centre | Log in',
  },
  {
    path: 'logout',
    component: LogoutPageComponent,
    title: 'Aptem Assess Admin Centre | Logged out',
  },
  {
    path: 'error',
    component: ErrorComponent,
    title: 'Aptem Assess Admin Centre | error',
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '/error',
  },
];
